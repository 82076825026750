import React from 'react';

function Credit() {
  return (

    <div className="credit">
        Hllo
    </div>
  )
}

export default Credit;
